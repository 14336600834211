.home-wrapper{
    height: auto;
    display: flex;
    flex-direction: column;

}

.home-wrapper>div{
    margin: 0 0 40px 0;
}

.home-utah{
    width: 90%;
    padding: 0 5%;
}

.home-ban{
    width: 100vw;
    height: auto;
}

.home-ban-text{
    color: #2a6eac;
    text-align: center;
}


.home-ban-h2{
    font-size: 2em;
    font-weight: 700;
    margin: 20px 10px;

}

.home-ban-para{
    font-weight: 500;
    font-size: 1.25em;
    margin: 10px;
}

.home-images{
    width: 100%;
}

.home-carousel{
    width: 85%;
    height: 200px;
    border: 10px solid #cde6f2;
    margin: 15px auto;
}

@media(min-width: 1024px){

    .home-wrapper{
        padding: 0 10%;
    }

    .home-ban{
        display: flex;
        width: 100%;
        margin-top: 20px;

    }

    .home-ban-h2{
        font-size: 1.75em;
        width: 100%;

    }
    .home-ban-para{
        font-size: 1em;
        width: 100%;
    }
    .home-ban-text{
        width: 500px;
        margin-top: 20px;
    }

    .home-images{
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .home-utah{
        width: 350px;
        height: 375px;
        padding: 0;
    }

    .home-carousel{
        width: 330px;
        height: 355px;
        margin: 0;
    }

}

@media(min-width: 1366px){
    .home-utah{
        width: 430px;
        height: 475px;
    }

    .home-carousel{
        width: 410px;
        height: 455px;
    }

    .home-ban-text{
        margin-top: 70px;
    }
}