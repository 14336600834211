.contact-wrapper{
    background: url('../../images/background.png') center;
    min-height: 100vh;
    height: auto;
    padding-bottom: 40px;
    width: 100vw;
}

.contact-h2{
    color: #2a6eac;
    font-size: 2.5em;
    font-weight: 600;
    text-align: center;
    margin: 10px;
}

.guy-pic{
    width: 75%;
    height: auto;
    margin: 10px 12%
}

.validation{
    color: #eb6931;
    font-weight: 600;
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: 1.2em;
    display: none;
}

.false{
    display:block;
}

.form-wrapper{
    background-color: white;
    width: 80%;
    margin: 40px auto;
}

input, textarea{
    background-color: lightgray;
    border-radius: 0;
    border: none;
    width: 125px;
}
.form-wrapper>div{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

.form-wrapper>div>div{
    margin-top: 10px;
}

.contact-button{
    height: 50px;
    width: auto;
    margin: 20px 0 20px 60%;
    background-color: lightgray;
    color:#2a6eac;
    font-weight: 600;
    font-size: 1em;
}

.comment-individual{
    height: 75px;

}

@media(min-width:820px){

    .contact-wrapper{
        display: flex;
        flex-direction: column;
    }

    .contact-h2{
        font-size: 2.7em;
    }

    .guy-pic{
        width: 600px;
        margin: 40px auto;
    }

    .form-wrapper{
        height: auto;
    }

    .validation{
        font-size: 2em;
        width: 600px;
        margin: 50px auto;
    }

    input, textarea{
        width: 200px;
        height: 1.5em;

    }

    .form-wrapper>div{
        display: flex;
        justify-content: space-between;
        width: 400px;
        margin: 0 auto;
        font-size: 1.5em;
    }

    .form-wrapper>div>div{
        margin-top: 20px;
    }

    .comment-individual{
        height: 125px;
    
    }

    .contact-button{
        font-size: 2em;
        margin: 30px 0 30px 360px;
    }


}

@media(min-width:1180px){
    .contact-h2{
        margin-top: 50px;
    }

    .form-wrapper{
        width: 600px;
    }

    .contact-button{
        margin-left: 330px;
    }
}


