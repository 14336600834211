.menu-wrapper{
    height: 50px;
    width: 100vw;
    position: fixed;
    padding: 0 10px;
    color: #2a6eac;
    background-color: white;

}

.brgr{
    height: 25px;
    width: 25px;
    margin: 10px 0;
    background-color: white;
}



.navlog{
    display: none;
}

.menu-items-visible{
    position: absolute;
    height: 400px;;
    width: 275px;
    display: block;
    font-size: 2.5em;
    border-color: #cde6f2;
    border-width: 2px;
    border-style: solid solid solid;
    transition-duration: 1s;
    background-color: #fff;
    padding-top: 25px;

    left: 0;
}

.menu-items-hidden{
    left: -300px;
}



a{
    text-decoration: none;
    color: #2a6eac;
}


.menu-items-visible>div{
  margin: 0% 10px 20% 10px;  
}

@media(min-width: 1024px){
    .menu-wrapper{
        
    }
    .burger-wrapper{
        display: none;
    }

    .menu-wrapper{
        position: static;
        height: auto;
        padding: 0;
    }

    .menu-items-visible{
        position: static;
        width: 100%;
        height: 90px;
        display: flex;
        border-style: none;
        font-size: 2em;
        justify-content: space-around;
    }

    .navlog{
        display: initial;
    }
}