.about-wrapper{
    background: url('../../images/background.png') center;
    min-height: 100vh;
    height: auto;
    color: #2a6eac;
}

.about-body-images>div>div{
    text-align: center;
}

.about-text{
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    
}

.about-h2>p{
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    margin-block-start: 0;
}


.about-header{
    height: auto;
    background-color: white;
    width: 90vw;
    margin: 0 5vw;
    display: flex;
    flex-direction: column-reverse;
}

.about-salsa{
    width: 100%;
    display: flex;
}

.salsa-image{
    margin: 15px auto;
}

.about-para{
    padding: 5px 10px;
    margin-bottom: 20px;
}

.about-body{
    background-color: rgba(114, 188, 220, .4);
    width: 90vw;
    margin: 0 5vw;
    padding: 10px 0;
}

.about-body-h2{
    color: #eb6931;
    font-weight: 700;
    font-size: 1.75em;
    opacity: 1;
    text-align: center;
}

.about-body-images{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.about-photo{
    height: 120px;
    width: 100px;
    margin: 10px;

}


.about-footer{
    height: auto;
    background-color: white;
    width: 90vw;
    margin: 0 5vw;
    text-align: center;
    padding: 15px 0;
}

.about-share{
    font-weight: 700;
    font-size: 1.75em;
    color: #bf3b26;
    margin-bottom: 10px;
}

.about-share-para{
    font-size: 1.25em;
    margin-bottom: 10px;
}

.about-share-image{
    margin-bottom: 10px;
}

.about-content-wrapper{
    padding-bottom: 50px;
}

@media(min-width: 820px){
    .about-header, .about-body, .about-footer{
        width: 80%;
        margin: 0 10%
    }

    .about-para, .about-share-para{
        font-size: 2em;
        padding: 10px 50px;
    }

    .about-h2>p{
        font-size: 3em;
    }

    .salsa-image{
        height: auto;
        width: 200px;
        margin: 45px auto;
    }

    .about-share{
        font-size: 3em;
    }

    .about-share-image {
        width: 500px;
        height: auto;
    }

    .about-body{
        height: auto;
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .about-body-h2{
        margin: 30px 0;
        font-size: 2.5em;
    }
    
}

@media(min-width:1180px){
    .about-header{
        flex-direction:row;
        width: 70%; 
        margin: 50px 15% 0 15%;
        
    }

    .about-text{
        margin: 50px 0;
        height: 250px;
        justify-content: space-between;
    }

    .about-h2>p{
        height: 10px;
        padding: 0 50px;
        text-align: left;
        font-size: 2em;

    }

    .about-para{
        text-align: left;
        font-size: 1.5em;
    }

    .salsa-image{
        height: 250px;
        width: auto;
        margin: 50px 0;
    }

    .about-body{
        width: 70%;
        margin: 0 15%;
    }

    .about-body-h2{
        font-size: 2em;

    }

    .about-body-images{
        width: 600px;
        margin: 0 auto;
    }

    .about-photo{
        margin: 0;
        width: 100px;
    }

    .about-footer{
        width: 70%;
        margin: 0 15%;
        display: flex;
        flex-direction: row;
    }

    .about-share{
        font-size: 2em;
        height: 50px;
        text-align: left;
        padding: 5px 100px;
    }

    .about-share-para{
        text-align: left;
        font-size: 1.2em;
        height: 150px;
        padding: 10px 100px;
    }

    .about-share-image{
        height: 150px;
        width: auto;
        margin-top:50px;
        padding-right: 50px;

    }

    @media(min-width: 1366px){

        .about-text{
            height: 300px;
        }
        .about-h2>p{
            height: 15px;
            padding: 0 100px;
            font-size: 2.5em;
        }

        .about-para{
            padding: 20px 100px;
        }

        .salsa-image{
            height: 270px;
        }

        .about-share{
            font-size: 2.5em;
            height: 50px;

        }

        .about-share-para{
            font-size: 1.7em;
            height: 150px;
        }

        .about-share-image{
            margin-top: 75px;
            height: 150px;
        }
    }


}

