.splash-wrapper{
    background: url('../../images/splash.png') center;
    background-size: cover;
    height: 100vh;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.splash-logo{
    height: 250px;
    width: auto;

    
    
}



    @media(min-width:414px){

        .splash-logo{
            height: 350px;
            margin-bottom: 100px;
        }
    }

    @media(min-width: 768px){
        .splash-logo{
            height: 700px;
        }
    }

    @media(min-width: 1024px){
        .splash-logo{
            height: 550px;
        }
    }

    @media(min-width: 1366px){
        .splash-logo{
            height: 600px;
        }
    }

    