.thanks-wrapper{
    background: url('../../images/background.png');
    min-height: 100vh;
    height: auto;

}

.thanks-content{
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.thanks-h2{
    text-align: center;
    font-size: 3em;
    font-weight: 600;
    color: #eb6931;
    margin: 100px 0;
}

.thanks-paragraph{
    text-align: center;
    font-size: large;
    padding:15px;
    font-weight:400;
    background-color: white;
    color: #2a6eac;

}

@media(min-width:820px){
    .thanks-h2{
        font-size: 5em;
    }

    .thanks-paragraph{
        font-size: 3em;
    }
}