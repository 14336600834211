.prices-wrapper{
    background: url('../../images/background.png') center;
    min-height: 100vh;
    height: auto;
    padding-bottom: 50px;

}

.pricing-guide-wrapper{
    width: 80%;
    margin: 0 10%;
    height: auto;
}

.pricing-guide-head{
    background-color: #bf3b26;
    color: white;
    font-size: 3em;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    padding-top: 50px;

}

.pricing-guide-items, .fun-bundle-wrapper{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding:  10px;
}

.fun-bundle-wrapper{
    margin-top: 40px;
}

.pricing-guide-wrapper, .fun-bundle-content{
    margin-bottom: 50px;
    
}

.pricing-guide-items-head{
    background-color: #2a6eac;
    height: auto;
    padding: 10px 0px;
    color: white;
    font-size: 2.2em;
    margin-bottom: 25px;
    text-align: center;
}

.pricing-guide-individual-items{
    padding-left: 15px;
}

.pricing-guide-individual-items>div{
    margin-top: 10px;
}

p{
    margin: 3px 0 0 15px;

}

.pricing-guide-price{
    width: auto;
    background-color: #2a6eac;
    text-align: center;
    margin-top: 25px;
    color: white;
    height: 3vh;
    font-size: 1.5em;

}

.fun-bundle-wrapper{
    text-align: center;
}

.fun-bundle-companies>div{
    margin-top: 20px;
    border-top:2px solid #f0a234;

}

.fun-bundle-header{
    background-color: #eb6931;
    height: auto;
    padding: 10px 0px;
    color: white;
    font-size: 2.2em;
    margin-bottom: 25px;
    text-align: center;
}

.fun-bundle-sub{
    font-weight: 900;
    text-align: center;
    margin: 10px;
}

.fun-bundle-text{
    margin: 20px 0;
    padding: 10px;
    line-height: 2em;
    font-weight: 400;
    border-top:2px solid #eb6931;

}

.company-header{
    font-weight: 900;
    margin: 10px;
    font-size: 1.2em;
}

.business-image{
    border-radius: 50%;
    margin: 20px 0;
    height: auto;
    width: 125px;
}

.company-about{
    margin: 20px;
    font-weight: 200;
}

a{
    color: black;
    font-weight: 900;
}

@media(min-width: 820px){
    .pricing-guide-wrapper{
        width: 75%;
        margin: 0 auto;
    }

    .pricing-guide-head{
       font-size: 4em;
       height: auto;
        padding: 10px 0;
    }

    .pricing-guide-items-head{
        font-size: 3.5em;
        padding: 10px;
    }

    .pricing-guide-individual-items{
        display: flex;
        flex-wrap: wrap;
    }

    .pricing-guide-individual-items>div{
        width: 50%;
        font-size: 1.5em;
    }

    .pricing-guide-price{
        height: auto;
        font-size: 3em;
    }

    .fun-bundle-header{
    
        font-size: 3.5em;
       
    }

    .fun-bundle-sub{
        font-size: 1.75em;
    }
        
    .fun-bundle-text{
        font-size: 1.25em;
        padding: 25px 70px;
    }

    .business-image{
        width:175px;
        margin-top: 50px;
    }

    .company-header{
        font-size: 1.6em;;
    }

    .company-about{
        font-size: 1.2em;
        width: 50%;
        margin: 30px auto;
    }

    a{
        font-size: 1.3em;
    }
    .fun-bundle-companies>div{
        margin-bottom: 50px;
    }
}

